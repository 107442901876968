import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../../../app/config";
import { reactSelectCustom, dashboardOption } from "../../../../app/constant";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal } from "react-bootstrap";
import { logout } from "../../../../app/modules/Auth/_redux/authCrud";
import {
  getAdminClinicList,
  getAllPoliciesAction,
  getPolicesByRoleIdAction,
  getPolicesByRoleIdSidebarAction,
} from "../../../../redux/actions";
import { GENERIC } from "../../../../redux/actions/utilities";
import { useFilters } from "../../../../app/constant/GenericFilterDetails";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export function IsNamePermissionAllowed() {
  return IsPermissionAllowed("pt:patientName")
}

export function IsMrnPermissionAllowed() {
  return IsPermissionAllowed("pt:patientMRN")
}


export function IsPermissionAllowed(permission) {
  let policesData = useSelector((state) => state?.clinicsReducer);
  return (
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
      (items) => items?.value === permission
    ).length > 0
  );
  //return isPermissionAllowed;
}





export function Header() {
  const { user } = useSelector((state) => state.auth);
  const { statsFilters, setStatsFilters } = useFilters();
  let policesData = useSelector((state) => state?.clinicsReducer);
  const isPermissionAllowed = (value) => {
    return (
      policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
        (items) => items?.value === value
      ).length > 0
    );
  };
  useEffect(() => {
    let req = {
      AdminId: 0
    }
    dispatch(getAdminClinicList(req));
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(getPolicesByRoleIdAction(user?.data?.policyGroupId));
  }, []);
  useEffect(() => {
    dispatch(getAllPoliciesAction());
  }, []);
  const isSectionPermissionAllowed = (value) => {
    return (
      policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
        (items) => items?.value.startsWith(value)
      ).length > 0
    );
  };
  const [dashboard, setDashboard] = useState({});

  let roleId = user?.data?.roleIds?.[0];
  let dispatch = useDispatch();
  useEffect(() => {
    if (user?.data?.policyGroupId)
      dispatch(getPolicesByRoleIdSidebarAction(user?.data?.policyGroupId));
  }, []);

  const uiService = useHtmlClassService();
  const history = useHistory();
  const location = useLocation();
  // const [selectedSection, setSelectedSection] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(true);


  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
      if (value === 4) {
        return history.push(PATH.OPD_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 5) {
        return history.push(PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 6) {
        return history.push(PATH.PRIMARY_HEALTH_UNIT_DASHBOARD);
      }
      if (value === 7) {
        return history.push(PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD);
      }
      if (value === 8) {
        return history.push(PATH.POSTNATAL_CARE_DASHBOARD);
      }
      if (value === 9) {
        return history.push(PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD);
      }
      if (value === 10) {
        return history.push(PATH.WASH_REGISTER_DASHBOARD);
      }
      if (value === 11) {
        return history.push(PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD);
      }
      if (value === 12) {
        return history.push(PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD);
      }
      if (value === 13) {
        return history.push(PATH.OTP_DASHBOARD);
      }
      if (value === 14) {
        return history.push(PATH.SC_DASHBOARD);
      }
      if (value === 15) {
        return history.push(PATH.SUPPLY_MANAGEMENT_DASHBOARD);
      }
      if (value === 16) {
        return history.push(PATH.DASHBOARD_DETAILS);
      }

    }
  };

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const manueOptions = [
    { value: 1, label: "Global Dashboard" },
    { value: 2, label: "Immunization" },
    { value: 3, label: "Antenatal Care" },
    { value: 4, label: "OPD Over Five" },
    { value: 5, label: "OPD Under Five" },
    { value: 6, label: "Primary Health Unit" },
    { value: 7, label: "Hospital InPatient Register" },
    { value: 8, label: "Posta Natal Care" },
    { value: 9, label: "Labour Delivery Maternity" },
    { value: 10, label: "Wash Register" },
    { value: 11, label: "Protection Monthly Reporting" },
    { value: 12, label: "Emergency Preparedness Monthly Reporting" },
    { value: 13, label: "OTP Dashboard" },
    { value: 14, label: "SC Dashboard" },
    { value: 15, label: "Supply Management Dashboard" },
    { value: 16, label: "OTP vs SC" },
  ];

  // Modify the options based on viewState and viewState2
  let modifiedOptions = [];
  if (isSectionPermissionAllowed("md:globalDashboard"))
    modifiedOptions = [...modifiedOptions, manueOptions[0]];
  if (isSectionPermissionAllowed("md:immunization"))
    modifiedOptions = [...modifiedOptions, manueOptions[1]];
  if (isSectionPermissionAllowed("md:antenatalCare"))
    modifiedOptions = [...modifiedOptions, manueOptions[2]];
  if (isSectionPermissionAllowed("md:overFive"))
    modifiedOptions = [...modifiedOptions, manueOptions[3]];
  if (isSectionPermissionAllowed("md:underFive"))
    modifiedOptions = [...modifiedOptions, manueOptions[4]];
  if (isSectionPermissionAllowed("md:primaryHealthUnit"))
    modifiedOptions = [...modifiedOptions, manueOptions[5]];
  if (isSectionPermissionAllowed("md:inPatientRegister"))
    modifiedOptions = [...modifiedOptions, manueOptions[6]];
  if (isSectionPermissionAllowed("md:postaNatalCare"))
    modifiedOptions = [...modifiedOptions, manueOptions[7]];
  if (isSectionPermissionAllowed("md:labourDeliveryMaternity"))
    modifiedOptions = [...modifiedOptions, manueOptions[8]];
  if (isSectionPermissionAllowed("md:washRegister"))
    modifiedOptions = [...modifiedOptions, manueOptions[9]];
  if (isSectionPermissionAllowed("md:protectionMonthlyReporting"))
    modifiedOptions = [...modifiedOptions, manueOptions[10]];
  if (isSectionPermissionAllowed("md:emergencyPreparednessMonthlyReporting"))
    modifiedOptions = [...modifiedOptions, manueOptions[11]];
  if (isSectionPermissionAllowed("md:otp"))
    modifiedOptions = [...modifiedOptions, manueOptions[12]];
  if (isSectionPermissionAllowed("md:sc"))
    modifiedOptions = [...modifiedOptions, manueOptions[13]];
  if (isSectionPermissionAllowed("md:otp"))
    modifiedOptions = [...modifiedOptions, manueOptions[14]];
  if (isSectionPermissionAllowed("md:otp"))
    modifiedOptions = [...modifiedOptions, manueOptions[15]];

  const handleDashboard = (current) => {
    let option = [...modifiedOptions];
    ;
    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      if (option.length > 2) return setDashboard(option[1]);
      else return setDashboard(option[0]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[option.length - 14]);
    }
    if (current === PATH.OPD_FIVE_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 13]);
    }
    if (current === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 12]);
    }
    if (current === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD) {
      return setDashboard(option[option.length - 11]);
    }
    if (current === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 10]);
    }
    if (current === PATH.POSTNATAL_CARE_DASHBOARD) {
      return setDashboard(option[option.length - 9]);
    }
    if (current === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD) {
      return setDashboard(option[option.length - 8]);
    }
    if (current === PATH.WASH_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 7]);
    }
    if (current === PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard(option[option.length - 6]);
    }
    if (current === PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard(option[option.length - 5]);
    }
    if (current === PATH.OTP_DASHBOARD) {
      return setDashboard(option[option.length - 4]);
    }
    if (current === PATH.SC_DASHBOARD) {
      return setDashboard(option[option.length - 3]);
    }
    if (current === PATH.SUPPLY_MANAGEMENT_DASHBOARD) {
      return setDashboard(option[option.length - 2]);
    }
    if (current === PATH.DASHBOARD_DETAILS) {
      return setDashboard(option[option.length - 1]);
    }

    return setDashboard(option[0]);
  };
  function IsBatch(isBatch) {
    setStatsFilters({
      ...statsFilters,
      filters: {
        ...statsFilters.filters,
        isBatchFilter: isBatch
      }
    });

  }
  const [selectedState, setselectedState] = useState(null);
  useEffect(() => {
    localStorage.setItem("StateId", selectedState?.value);
  }, [selectedState]);
  const changeState = (value) => {
    setselectedState({ label: value?.label, value: value?.value });
    ;
  };
  useEffect(() => {
    let option = [...modifiedOptions];
    ;
    if (location.pathname === PATH.MAIN_DASHBOARD) {
      return setDashboard({ value: 1, label: "Global Dashboard" });
    }
    if (location.pathname === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard({ value: 2, label: "Immunization" });
    }
    if (location.pathname === PATH.ANC_DASHBOARD) {
      return setDashboard({ value: 3, label: "Antenatal Care" });
    }
    if (location.pathname === PATH.OPD_FIVE_REGISTER_DASHBOARD) {
      return setDashboard({ value: 4, label: "OPD Over Five" });
    }
    if (location.pathname === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD) {
      return setDashboard({ value: 5, label: "OPD Under Five" });
    }
    if (location.pathname === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD) {
      return setDashboard({ value: 6, label: "Primary Health Unit" });
    }
    if (location.pathname === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD) {
      return setDashboard({ value: 7, label: "Hospital InPatient Register" });
    }
    if (location.pathname === PATH.POSTNATAL_CARE_DASHBOARD) {
      return setDashboard({ value: 8, label: "Posta Natal Care" });
    }
    if (location.pathname === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD) {
      return setDashboard({ value: 9, label: "Labour Delivery Maternity" });
    }
    if (location.pathname === PATH.WASH_REGISTER_DASHBOARD) {
      return setDashboard({ value: 10, label: "Wash Register" });
    }
    if (location.pathname === PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard({ value: 11, label: "Protection Monthly Reporting" });
    }
    if (location.pathname === PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard({ value: 12, label: "Emergency Preparedness Monthly Reporting" });
    }
    if (location.pathname === PATH.OTP_DASHBOARD) {
      return setDashboard({ value: 13, label: "OTP Dashboard" });
    }
    if (location.pathname === PATH.SC_DASHBOARD) {
      return setDashboard({ value: 14, label: "SC Dashboard" });
    }
    if (location.pathname === PATH.SUPPLY_MANAGEMENT_DASHBOARD) {
      return setDashboard({ value: 15, label: "Supply Management Dashboard" });
    }
    if (location.pathname === PATH.DASHBOARD_DETAILS) {
      return setDashboard({ value: 16, label: "OTP vs SC" });
    }
    return setDashboard(option[0]);
  }, [location])


  // Open Relogin modal if sytem idle more than 30 minutes

  let idleTimeout;
  useEffect(() => {
    const resetIdleTimer = () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      idleTimeout = setTimeout(() => {
        dispatch({
          type: GENERIC.SET_SESSION_EXPIRE_STATE,
          payload: true,
        });
      }, 1800000);
      // }, 60000);
    };
    window.addEventListener('mousemove', resetIdleTimer);
    window.addEventListener('keydown', resetIdleTimer);
    resetIdleTimer();
    return () => {
      window.removeEventListener('mousemove', resetIdleTimer);
      window.removeEventListener('keydown', resetIdleTimer);
      clearTimeout(idleTimeout);
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (history?.location?.pathname === "/auth/login") {
  //     window.localStorage.removeItem("tokenExpiry");
  //     window.localStorage.removeItem("user_refresh_token");
  //     history.push("/logout");
  //   }
  // }, [])

  useEffect(() => {
    if (!user?.data || history?.location?.pathname === "/auth/login") {
      window.localStorage.removeItem("tokenExpiry");
      window.localStorage.removeItem("user_refresh_token");
      history.push("/logout");
    }
  }, [])
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    globalDashboard: false,
    otpDashboard: false,
    ScDashboard: false,
    SupplyManagementDashboard: false,
  });
  const [activeTab, setActiveTab] = useState("globalDashboard");
  const [selectedSection, setSelectedSection] = useState({
    globalDashboard: "Overview",
    otpDashboard: "Overview",
    ScDashboard: "Overview",
    SupplyManagementDashboard: "Overview",
  });
  const handleChildTab = (innerTab) => {
    debugger
    setStatsFilters((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        innerTab: innerTab
      }
    }))
    setIsDropdownOpen(
      {
        globalDashboard: false,
        otpDashboard: false,
        ScDashboard: false,
        SupplyManagementDashboard: false,
      }
    )

  }
 

  const dashboardSections = {
    globalDashboard: ["Overview", "Nutrition Summary", "NotifiedUsers, IVR",],
    otpDashboard: ["Overview", "Admissions", "Discharge Summary"],
    ScDashboard: ["Overview", "Admissions", "Discharge Summary"],
    SupplyManagementDashboard: ["SM Dashboard"],
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setStatsFilters((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        activeTab: tab,
        isBatchFilter: tab === "SupplyManagementDashboard" ? true : false
      },
    }));
    setSelectedSection({ ...selectedSection, [tab]: null });
    // Close dropdown when switching tabs
    setIsDropdownOpen({ ...isDropdownOpen, [tab]: false });
  };
  function IsBatch(isBatch) {
    setStatsFilters({
      ...statsFilters,
      filters: {
        ...statsFilters.filters,
        isBatchFilter: isBatch
      }
    });

  }
  const toggleDropdown = (tab) => {
    setIsDropdownOpen((prev) => ({
      ...prev,
      [tab]: !prev[tab],
    }));
  };



  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className="header-menu header-menu-mobile align-items-center"
            >
              {history?.location?.pathname === "/dashboard" ?
                // <div className="tabs-container">
                //   {/* Tab Buttons */}
                //   <div className="tab-buttons">
                //     {(isSectionPermissionAllowed("md:globalDashboard")) &&
                //       <div
                //         className={`tab-button ${activeTab === "globalDashboard" ? "active" : ""}`}
                //         onClick={() => { handleTabChange("globalDashboard"); IsBatch(false) }}
                //       >
                //         Global Dashboard
                //       </div>}
                //     {(isSectionPermissionAllowed("md:otp")) &&
                //       <div className="mx-5 px-3">
                //         <button
                //           className={` tab-button ${activeTab === "otpDashboard" ? "active" : ""}`}
                //           onClick={() => { handleTabChange("otpDashboard"); IsBatch(false) }}
                //         >
                //           OTP Dashboard
                //         </button>
                //       </div>}
                //     {(isSectionPermissionAllowed("md:sc")) &&
                //       <button
                //         className={`tab-button ${activeTab === "ScDashboard" ? "active" : ""}`}
                //         onClick={() => { handleTabChange("ScDashboard"); IsBatch(false) }}
                //       >
                //         SC Dashboard
                //       </button>}
                //     {(isSectionPermissionAllowed("md:sm")) &&
                //       <div className="mx-5 px-3">
                //         <button
                //           className={`tab-button ${activeTab === "SupplyManagementDashboard" ? "active" : ""}`}
                //           onClick={() => { handleTabChange("SupplyManagementDashboard"); IsBatch(true) }}
                //         >
                //           SM Dashboard
                //         </button>
                //       </div>}

                //   </div> </div> :
                // <div className="tabs-container">
                //   {/* Tab Buttons */}
                //   <div className="tab-buttons">
                //     {isSectionPermissionAllowed("md:globalDashboard") && (
                //       <div
                //         className={`tab-button ${activeTab === "globalDashboard" ? "active" : ""}`}
                //         onClick={() => handleTabChange("globalDashboard")}
                //       >
                //         {activeTab === "globalDashboard" ? <FaChevronUp className="mr-2" color="#13B9A0" /> : <FaChevronDown className="mr-2" color="#13B9A0" />}
                //         Global Dashboard
                //       </div>
                //     )}
                //     {isSectionPermissionAllowed("md:otp") && (
                //       <div className="mx-5 px-3">
                //         <button
                //           className={`tab-button ${activeTab === "otpDashboard" ? "active" : ""}`}
                //           onClick={() => handleTabChange("otpDashboard")}
                //         >
                //           OTP Dashboard
                //         </button>
                //       </div>
                //     )}
                //     {isSectionPermissionAllowed("md:sc") && (
                //       <button
                //         className={`tab-button ${activeTab === "ScDashboard" ? "active" : ""}`}
                //         onClick={() => handleTabChange("ScDashboard")}
                //       >
                //         SC Dashboard
                //       </button>
                //     )}
                //     {isSectionPermissionAllowed("md:sm") && (
                //       <div className="mx-5 px-3">
                //         <button
                //           className={`tab-button ${activeTab === "SupplyManagementDashboard" ? "active" : ""}`}
                //           onClick={() => handleTabChange("SupplyManagementDashboard")}
                //         >
                //           SM Dashboard
                //         </button>
                //       </div>
                //     )}
                //   </div>

                //   {/* Dropdown for Global Dashboard */}
                //   {dropdownVisible && (
                //     <div style={{
                //       textAlign: "justify",
                //       background: "#1db6af",
                //       padding: "15px",
                //       position: "absolute",
                //       color: "white",
                //       borderRadius: "6px"
                //     }}>
                //       {globalSections.map((section) => (
                //         <div
                //           key={section}
                //           style={{ color: selectedSection === section ? "#13B9A0" : "white", backgroundColor: selectedSection === section ? "white" : "#13B9A0", padding: "5px", paddingBottom: "0px", borderRadius: "4px" }}
                //           // className={`${selectedSection === section ? "selected" : ""}`}
                //           onClick={() => setSelectedSection(section)}
                //         >
                //           {section}
                //         </div>
                //       ))}
                //     </div>
                //   )}

                //   {/* Section Content */}
                // </div>
                <div className="tabs-container">
                  <div className="tab-buttons">
                    {/* Global Dashboard */}
                    {isSectionPermissionAllowed("md:globalDashboard") && (
                      <div className="relative mx-2 px-3" style={{ cursor: "pointer" }} onClick={(e) => {
                        e.stopPropagation();
                        if (activeTab === "globalDashboard") {
                          toggleDropdown("globalDashboard");
                        }
                      }}>
                        <div
                          style={{ lineHeight: activeTab === "globalDashboard" ? "40px" : "40px" }}
                          className={`tab-button ${activeTab === "globalDashboard" ? "active" : ""}`}
                          onClick={() => { handleTabChange("globalDashboard"); handleChildTab("Overview") }}
                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown("globalDashboard");
                            }}
                          >
                            {activeTab === "globalDashboard" && (
                              <FaChevronDown className="mr-2" color="#13B9A0" />
                            )}
                          </span>
                          Global Dashboard
                        </div>
                        {activeTab === "globalDashboard" && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "5px",
                              position: "absolute",
                              borderRadius: "6px",
                              zIndex: 1000,
                              top: "31%",
                            }}
                          >
                            <span style={{
                              padding: "15px",
                              fontSize: "10px",
                              paddingLeft: "2px",
                              color: "#808181",
                              borderRadius: "6px",
                            }}><FaArrowRightLong style={{
                              fontSize: "8px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                            }} /> {selectedSection?.globalDashboard ?? dashboardSections?.globalDashboard[0]}</span>
                          </div>
                        )}
                        {activeTab === "globalDashboard" && isDropdownOpen.globalDashboard && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "6px",
                              width: "180px",
                              background: "rgb(255 255 255)",
                              position: "absolute",
                              zIndex: 1000,
                              top: "83%",
                              width: "158px",
                              boxShadow: "2px 2px 10px",
                              border: "1px solid #cecccc",
                              borderRadius: "4px",
                            }}
                            onClick={() => toggleDropdown("globalDashboard")}
                          >
                            {/* <MdCancel style={{
                              fontSize: "16px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                              position: "absolute",
                              right: "-9px",
                              cursor: "pointer",
                              top: "-6px"
                            }} onClick={() => toggleDropdown("globalDashboard")}/> */}
                            {dashboardSections.globalDashboard.map((section) => (
                              <div
                                key={section}
                                style={{
                                  color: selectedSection.globalDashboard === section ? "white" : "black",
                                  backgroundColor: selectedSection.globalDashboard === section ? "#13B9A0" : "white",
                                  padding: "8px 5px",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                                // onMouseEnter={(e) => {
                                //   if (selectedSection.globalDashboard !== section) {
                                //     e.target.style.backgroundColor = "#13B9A0";
                                //     e.target.style.color = "white";
                                //     e.target.style.padding = "5px";
                                //   }
                                // }}
                                // onMouseLeave={(e) => {
                                //   if (selectedSection.globalDashboard !== section) {
                                //     e.target.style.backgroundColor = "transparent";
                                //     e.target.style.color = "black";
                                //     e.target.style.padding = "5px";
                                //   }
                                // }}
                                onClick={() => { setSelectedSection({ ...selectedSection, globalDashboard: section }); handleChildTab(section) }}
                              >
                                {section}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                    )}

                    {/* OTP Dashboard */}
                    {isSectionPermissionAllowed("md:otp") && (
                      <div className="relative px-3" onClick={(e) => {
                        e.stopPropagation();
                        if (activeTab === "otpDashboard") {
                          toggleDropdown("otpDashboard");
                        }
                      }}>
                        <button
                          style={{ lineHeight: activeTab === "otpDashboard" ? "40px" : "40px" }}
                          className={`tab-button ${activeTab === "otpDashboard" ? "active" : ""}`}
                          onClick={() => { handleTabChange("otpDashboard"); handleChildTab("Overview") }}
                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent tab change when clicking icon
                              toggleDropdown("otpDashboard");
                            }}
                          >
                            {activeTab === "otpDashboard" && (
                              <FaChevronDown className="mr-2" color="#13B9A0" />
                            )}
                          </span>
                          OTP Dashboard
                        </button>

                        {activeTab === "otpDashboard" && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "5px",
                              position: "absolute",
                              borderRadius: "6px",
                              zIndex: 1000,
                              top: "31%",
                            }}
                          >
                            <span style={{
                              padding: "15px",
                              paddingLeft: "2px",
                              fontSize: "10px",
                              color: "#808181",
                              borderRadius: "6px",
                            }}><FaArrowRightLong style={{
                              fontSize: "8px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                            }} /> {selectedSection?.otpDashboard ?? dashboardSections?.otpDashboard[0]}</span>
                          </div>
                        )}

                        {activeTab === "otpDashboard" && isDropdownOpen.otpDashboard && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "6px",
                              width: "180px",
                              background: "rgb(255 255 255)",
                              position: "absolute",
                              zIndex: 1000,
                              top: "83%",
                              width: "158px",
                              boxShadow: "2px 2px 10px",
                              border: "1px solid #cecccc",
                              borderRadius: "4px",
                            }}
                            onClick={() => toggleDropdown("otpDashboard")}
                          >
                            {/* <MdCancel style={{
                              fontSize: "16px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                              position: "absolute",
                              right: "-9px",
                              cursor: "pointer",
                              top: "-6px" */}
                            {/* }} onClick={() => toggleDropdown("otpDashboard")}  /> */}
                            {dashboardSections.otpDashboard.map((section) => (
                              <div
                                key={section}
                                style={{
                                  color: selectedSection.otpDashboard === section ? "white" : "black",
                                  backgroundColor: selectedSection.otpDashboard === section ? "#13B9A0" : "white",
                                  padding: "8px 5px",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                                // onMouseEnter={(e) => {
                                //   if (selectedSection.otpDashboard !== section) {
                                //     e.target.style.backgroundColor = "#13B9A0";
                                //     e.target.style.color = "white";
                                //   }
                                // }}
                                // onMouseLeave={(e) => {
                                //   if (selectedSection.otpDashboard !== section) {
                                //     e.target.style.backgroundColor = "transparent";
                                //     e.target.style.color = "black";
                                //   }
                                // }}
                                onClick={() => { setSelectedSection({ ...selectedSection, otpDashboard: section }); handleChildTab(section) }}
                              >
                                {section}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {/* SC Dashboard */}
                    {isSectionPermissionAllowed("md:sc") && (
                      <div className="relative mx-2 px-3" onClick={(e) => {
                        e.stopPropagation();
                        if (activeTab === "ScDashboard") {
                          toggleDropdown("ScDashboard");
                        }
                      }}>
                        <button
                          style={{ lineHeight: activeTab === "ScDashboard" ? "40px" : "40px" }}
                          className={`tab-button ${activeTab === "ScDashboard" ? "active" : ""}`}
                          onClick={() => { handleTabChange("ScDashboard"); handleChildTab("Overview") }}

                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent tab change when clicking icon
                              toggleDropdown("ScDashboard");
                            }}
                          >
                            {activeTab === "ScDashboard" && (
                              <FaChevronDown className="mr-2" color="#13B9A0" />
                            )}
                          </span>
                          SC Dashboard
                        </button>
                        {activeTab === "ScDashboard" && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "5px",
                              position: "absolute",
                              borderRadius: "6px",
                              zIndex: 1000,
                              top: "31%",
                            }}
                          >
                            <span style={{
                              padding: "15px",
                              paddingLeft: "2px",
                              fontSize: "10px",
                              color: "#808181",
                              borderRadius: "6px",
                            }}><FaArrowRightLong style={{
                              fontSize: "8px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                            }} /> {selectedSection?.ScDashboard ?? dashboardSections?.ScDashboard[0]}</span>
                          </div>
                        )}
                        {activeTab === "ScDashboard" && isDropdownOpen.ScDashboard && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "6px",
                              width: "180px",
                              background: "rgb(255 255 255)",
                              position: "absolute",
                              zIndex: 1000,
                              top: "83%",
                              width: "158px",
                              boxShadow: "2px 2px 10px",
                              border: "1px solid #cecccc",
                              borderRadius: "4px",
                            }}
                            onClick={() => toggleDropdown("ScDashboard")}
                          >
                            {/* <MdCancel style={{
                              fontSize: "16px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                              position: "absolute",
                              right: "-9px",
                              cursor: "pointer",
                              top: "-6px"
                            }}  onClick={() => toggleDropdown("ScDashboard")} /> */}
                            {dashboardSections.ScDashboard.map((section) => (
                              <div
                                key={section}
                                style={{
                                  color: selectedSection.ScDashboard === section ? "white" : "black",
                                  backgroundColor: selectedSection.ScDashboard === section ? "#13B9A0" : "white",
                                  padding: "8px 5px",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => { setSelectedSection({ ...selectedSection, ScDashboard: section }); handleChildTab(section) }}
                              // onMouseEnter={(e) => {
                              //   if (selectedSection.ScDashboard !== section) {
                              //     e.target.style.backgroundColor = "#13B9A0";
                              //     e.target.style.color = "white";
                              //   }
                              // }}
                              // onMouseLeave={(e) => {
                              //   if (selectedSection.ScDashboard !== section) {
                              //     e.target.style.backgroundColor = "transparent";
                              //     e.target.style.color = "black";
                              //   }
                              // }}
                              >
                                {section}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {/* SM Dashboard */}
                    {isSectionPermissionAllowed("md:sm") && (
                      <div className="relative px-3" onClick={(e) => {
                        e.stopPropagation();
                        if (activeTab === "SupplyManagementDashboard") {
                          toggleDropdown("SupplyManagementDashboard");
                        }
                      }}>
                        <button
                          style={{ lineHeight: activeTab === "SupplyManagementDashboard" ? "40px" : "40px" }}
                          className={`tab-button ${activeTab === "SupplyManagementDashboard" ? "active" : ""}`}
                          onClick={() => { handleTabChange("SupplyManagementDashboard"); handleChildTab("Overview") }}

                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent tab change when clicking icon
                              toggleDropdown("SupplyManagementDashboard");
                            }}
                          >
                            {activeTab === "SupplyManagementDashboard" && (
                              <FaChevronDown className="mr-2" color="#13B9A0" />
                            )}
                          </span>
                          SM Dashboard
                        </button>
                        {activeTab === "SupplyManagementDashboard" && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "5px",
                              position: "absolute",
                              borderRadius: "6px",
                              zIndex: 1000,
                              top: "31%",
                            }}
                          >
                            <span style={{
                              padding: "15px",
                              paddingLeft: "2px",
                              fontSize: "10px",
                              color: "#808181",
                              borderRadius: "6px",
                            }}><FaArrowRightLong style={{
                              fontSize: "8px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                            }} /> {selectedSection?.SupplyManagementDashboard ?? dashboardSections?.SupplyManagementDashboard[0]}</span>
                          </div>
                        )}
                        {activeTab === "SupplyManagementDashboard" && isDropdownOpen.SupplyManagementDashboard && (
                          <div
                            style={{
                              textAlign: "justify",
                              padding: "6px",
                              width: "180px",
                              background: "rgb(255 255 255)",
                              position: "absolute",
                              zIndex: 1000,
                              top: "83%",
                              width: "158px",
                              boxShadow: "2px 2px 10px",
                              border: "1px solid #cecccc",
                              borderRadius: "4px",
                            }}
                            onClick={() => toggleDropdown("SupplyManagementDashboard")}
                          >
                            {/* <MdCancel style={{
                              fontSize: "16px",
                              marginRight: "3px",
                              marginBottom: "1px",
                              color: "#808181",
                              position: "absolute",
                              right: "-9px",
                              cursor: "pointer",
                              top: "-6px"
                            }}  onClick={() => toggleDropdown("SupplyManagementDashboard")} /> */}
                            {dashboardSections.SupplyManagementDashboard.map((section) => (
                              <div
                                key={section}
                                style={{
                                  color: selectedSection.SupplyManagementDashboard === section ? "white" : "black",
                                  backgroundColor: selectedSection.SupplyManagementDashboard === section ? "#13B9A0" : "white",
                                  padding: "8px 5px",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                              // onClick={() => { setSelectedSection({ ...selectedSection, SupplyManagementDashboard: section }); handleChildTab(section) }}
                              // onMouseEnter={(e) => {
                              //   if (selectedSection.SupplyManagementDashboard !== section) {
                              //     e.target.style.backgroundColor = "#13B9A0";
                              //     e.target.style.color = "white";
                              //   }
                              // }}
                              // onMouseLeave={(e) => {
                              //   if (selectedSection.SupplyManagementDashboard !== section) {
                              //     e.target.style.backgroundColor = "transparent";
                              //     e.target.style.color = "black";
                              //   }
                              // }}
                              >
                                {section}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                :
                <span style={{ color: "#0F172A", fontSize: "22px", fontWeight: "500" }}>
                  {history?.location?.pathname
                    ?.replace(/\/:\w+\//, "/") // Removes dynamic segments like /:id/
                    ?.replace(/\/\d+\//, "/") // Removes numeric segments like /59361/
                    ?.replace("/", "")
                    ?.replace(/([a-z])([A-Z])/g, '$1 $2')
                    ?.replace(/[_-]/g, " ")
                    ?.replace(/\b\w/g, (c) => c.toUpperCase())}
                </span>}
            </div>
          </div>


          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
      <SessionExpireModal />
    </>
  );
}

function SessionExpireModal() {
  let history = useHistory();
  const reLogin = () => {
    window.localStorage.removeItem("tokenExpiry");
    logout()
      .then((resp) => resp.data)
      .then((data) => { })
      .catch((response) => { })
      .finally(() => {
        history.push("/logout");
      });
  };
  const genericReducer = useSelector((state) => state.genericReducer);
  return (
    <Modal size="sm" centered show={genericReducer.sessionExpire}>
      <Modal.Body closeButton className="text-center mt-3">
        <h4>
          Session Expired
          <br />
          <small className="mt-2">
            Your current session has been expired, please login again to
            conitnue
          </small>
        </h4>
        <Button
          size="sm"
          className="mt-3"
          variant="primary"
          onClick={() => reLogin()}
        >
          Re-Login
        </Button>
      </Modal.Body>
    </Modal>
  );
}
