import React, { useEffect, useState } from "react";
import { OPTION } from "../../../constant";
import Select, { components } from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import { Col } from "react-bootstrap";
export const GenericDatePicker = ({
  childImmunization,
  setChildImmunization,
  setColumnSize, isLoading,
  isVertical = false, isDisabled, dateClear, setdateClear, setFilterOpt, filterOpt
}) => {
  //  const [filterOpt, setFilterOpt] = useState(null);
  const [isCustom, setIsCustom] = useState(false);
  let now = new Date();
  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate())
  );
  let end = moment(start)
    .add(1, "days")
    .subtract(1, "seconds");
  const { RangePicker } = DatePicker;
  function changeOption(data) {
    if (data === null) {
      setFilterOpt('')
      setChildImmunization({
        ...childImmunization,
        filters: {
          ...childImmunization.filters,
          StartDate: moment(start)
            .subtract(6, "days")
            .format("YYYY-MM-DD"),
          EndDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    }
    if (data?.value === "today") {
      setFilterOpt(data)
      setIsCustom(false);
      if (dateClear) {
        setdateClear(false)
      }
      setColumnSize(false);
      setChildImmunization({
        ...childImmunization,
        filters: {
          ...childImmunization.filters,
          StartDate: moment(start).format("YYYY-MM-DD"),
          EndDate: moment(start).format("YYYY-MM-DD"),
        },
      });
    } else if (data?.value === "yesterday") {
      setFilterOpt(data)
      setIsCustom(false);
      if (dateClear) {
        setdateClear(false)
      }
      setColumnSize(false);
      setChildImmunization({
        ...childImmunization,
        filters: {
          ...childImmunization.filters,
          StartDate: moment(start)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          EndDate: moment(start)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        },
      });
    } else if (data?.value === "7") {
      setFilterOpt(data)
      setIsCustom(false);
      if (dateClear) {
        setdateClear(false)
      }
      setColumnSize(false);
      setChildImmunization({
        ...childImmunization,
        filters: {
          ...childImmunization.filters,
          StartDate: moment(start)
            .subtract(6, "days")
            .format("YYYY-MM-DD"),
          EndDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    } else if (data?.value === "30") {
      setFilterOpt(data)
      setColumnSize(false);
      if (dateClear) {
        setdateClear(false)
      }
      setIsCustom(false);
      setChildImmunization({
        ...childImmunization,
        filters: {
          ...childImmunization.filters,
          StartDate: moment(start)
            .subtract(29, "days")
            .format("YYYY-MM-DD"),
          EndDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    } else if (data?.value === "90") {
      setFilterOpt(data)
      setColumnSize(false);
      if (dateClear) {
        setdateClear(false)
      }
      setIsCustom(false);
      setChildImmunization({
        ...childImmunization,
        filters: {
          ...childImmunization.filters,
          StartDate: moment(start)
            .subtract(89, "days")
            .format("YYYY-MM-DD"),
          EndDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    } else if (data?.value === "custom") {
      setColumnSize(true);
      if (dateClear) {
        setdateClear(false)
      }
      setIsCustom(true);
    }
  }
  function onchangeFuhnc(e, b) {
    setChildImmunization({
      ...childImmunization,
      filters: {
        ...childImmunization.filters,
        StartDate: b[0],
        EndDate: b[1],
      },
    });
  }
  return (
    <div className={isVertical ? 'col' : 'row'}>
      <Col lg="12">
        <Select
          options={OPTION.ANALYTIC_FILTER_OPTION}
          className="d-inline-block w-100"
          placeholder="Select Date"
          isDisabled={isLoading || isDisabled}
          isClearable
          onChange={(value) => changeOption(value)}
          value={dateClear === true ? null : filterOpt}
          name="dateRange"
          defaultValue={filterOpt}
        />
      </Col>
      {isCustom && (
        <Col g="12">
          <div className=" pr-lg-0 mt-3 w-100">
            <div className="analytic-date-range w-100">
              <RangePicker
                format="YYYY/MM/DD"
                onChange={(value, dateString) =>
                  onchangeFuhnc(value, dateString)
                }
                className="custom-daterange"
              />
            </div>
          </div>
        </Col>
      )}
    </div>
  );
};
