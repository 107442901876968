/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Modal, Button, Container, Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import moment from 'moment';
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import TermsandCondtitions from "./modules/TermsAndConditions";
import { getAllLocationAction } from "../redux/actions/locationAction";
import { FaBaby, FaHandHoldingHeart, FaListUl, FaTachometerAlt, FaVideo } from "react-icons/fa";
import { PATH } from "./config";
import { FcDataConfiguration } from "react-icons/fc";
import { mainLogo } from "../_metronic/assets";
import { baseURL } from "../redux/config";
import Axios from "axios";
const DASHBOARDS = [
  {
    name: "Global Dashboard",
    description: "",
    value: "md:globalDashboard",
    subPolicies: null,
    path: PATH.MAIN_DASHBOARD,
    tags: null,
    icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
  },
  {
    name: "Immunization",
    description: "",
    value: "md:immunization",
    subPolicies: null,
    path: PATH.IMMUNIZATION_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Antenatal Care",
    description: "",
    value: "md:antenatalCare",
    subPolicies: null,
    path: PATH.ANC_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "OPD Over Five",
    description: "",
    value: "md:overFive",
    subPolicies: null,
    path: PATH.OPD_FIVE_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "OPD Under Five",
    description: "",
    value: "md:underFive",
    subPolicies: null,
    path: PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Primary Health Unit",
    description: "",
    value: "md:primaryHealthUnit",
    subPolicies: null,
    path: PATH.PRIMARY_HEALTH_UNIT_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Hospital InPatient Register",
    description: "",
    value: "md:inPatientRegister",
    subPolicies: null,
    path: PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Posta Natal Care",
    description: "",
    value: "md:postaNatalCare",
    subPolicies: null,
    path: PATH.POSTNATAL_CARE_DASHBOARD,
    tags: null,
    icon: <FaListUl size="18" className="h-auto w-auto" />,
  },
  {
    name: "Labour Delivery Maternity",
    description: "",
    value: "md:labourDeliveryMaternity",
    subPolicies: null,
    path: PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD,
    tags: null,
    icon: <FaVideo size="18" className="h-auto w-auto" />,
  },
  {
    name: "Wash Register",
    description: "",
    value: "md:washRegister",
    subPolicies: null,
    path: PATH.WASH_REGISTER_DASHBOARD,
    tags: null,
    icon: <FaBaby size="18" className="h-auto w-auto" />,
  },
  {
    name: "Protection Monthly Reporting",
    description: "",
    value: "md:protectionMonthlyReporting",
    subPolicies: null,
    path: PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "Batch Numbers",
    description: "",
    value: "md:protectionMonthlyReporting",
    subPolicies: null,
    path: PATH.BATCH_NUMBERS,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "Emergency Preparedness Monthly Reporting",
    description: "",
    value: "md:emergencyPreparednessMonthlyReporting",
    subPolicies: null,
    path: PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "OTP",
    description: "",
    value: "md:otp",
    subPolicies: null,
    path: PATH.OTP_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "SC",
    description: "",
    value: "md:sc",
    subPolicies: null,
    path: PATH.SC_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },

];
export function Routes() {
  const { user } = useSelector((state) => state.auth);
  const token = window.localStorage.getItem('user_refresh_token'); // Get token from localStorage or your state
  const [isChecked, setIsChecked] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShowModal] = useState(false);
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${baseURL}/User/GetUserAgreement/?userId=${user?.data?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (token) {
      fetchData();
    }

  }, []);

  const history = useHistory();
  let dispatch = useDispatch()
  const handleClose = () => {
    window.localStorage.removeItem("tokenExpiry");
    window.localStorage.removeItem("user_refresh_token");
    history.push("/logout");// Redirect to login
    setShowModal(false);
  };

  const handleContinue = async () => {
    setShowModal(false);
    const payload = {
      userId: user?.data?.id, // Replace with correct property
      userAgreementId: user?.data?.newUserAgreementId,
    };
    debugger
    try {
      const response = await Axios.post(`${baseURL}/User/SaveUserAgreement`, payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the Authorization header
          },
        }
      ).then((response) => {
        setShowModal(false);
      }

      );
      // history.push("/dashboard"); // Redirect to dashboard
    } catch (error) {
      console.error("Error updating agreement:", error);
    }
    // history.push(filteredPolicies?.[0]?.path || "/"); // Redirect to dashboard
  };
  let policesData = useSelector((state) => state?.clinicsReducer);
  const filteredPolicies = DASHBOARDS.filter((item) =>
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.some(
      (subItem) => subItem.value === item.value
    )
  );
  useEffect(() => {
    debugger
    if (user && user?.data?.isAgreementUpdated === false) {
      setShowModal(true);
    }
  }, [user, dispatch])
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const formattedDate = moment().format('DD MMMM YYYY');
  return (
    <>

      <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>User Confidentiality Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-10 " >
          <div className="mb-5">
            <img src={mainLogo} />
          </div>
          <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
            <p>
              OGOW Health is responsible for protecting the confidentiality of information that it collects,
              uses, stores, and discloses over the course of its operations. You may have access to sensitive
              information as part of your duties and responsibilities related to your access to the platform.
            </p>
            <p>
              This form describes how you must handle information when engaging with <strong>OGOW</strong> Health
              systems, including OGOW information systems, and will help you comply with relevant OGOW policies
              (provided separately) and applicable privacy and data protection laws.
            </p>
            <p>
              This form is to be completed by all users assigned to accessing the <strong>OGOW</strong> Health platform.
              Completed forms will be retained in the appropriate corresponding program files.
            </p>
            <p>
              It is required that you read and understand the above-referenced policies and treat patient, personal, or other
              OGOW information as confidential. The protection of personal information is governed by both OGOW policy and
              privacy laws. You must sign this Agreement before OGOW will grant access to OGOW information or to an OGOW-owned
              or operated electronic system (<strong>“OGOW System”</strong>). This Agreement explains the rules and expectations
              related to securing and protecting OGOW information and systems. You may be required to comply with additional
              terms and conditions before accessing specific OGOW Systems.
            </p>
            <p><strong>Appropriate Collection, Use and Disclosure of Information</strong></p>
            <ul>
              <li>I shall only collect, access, use and disclose the minimum information necessary for the purpose of fulfilling my duties and responsibilities related to my role.</li>
              <li>I will not access information except as necessary for my Responsibilities.</li>
              <li>I shall ensure that information I enter into an OGOW System is complete and accurate to the best of my ability.</li>
              <li>I shall dispose of any information I access from an OGOW System (whether in electronic or paper format) in a secure manner as explained in OGOW policies and procedures.</li>
              <li>I shall use reasonable means to ensure that while I am accessing information on an OGOW System it will not be viewed or obtained by unauthorized people (e.g. secure my computer, be discreet when viewing data).</li>
              <li>I shall not collect, use, transmit or disclose any OGOW information except as allowed by OGOW policies and procedures.</li>
            </ul>

            <p><strong>System Security</strong></p>
            <ul>
              <li>I will keep any OGOW System login information, such as my user password, confidential and will not share this login information with anyone else.</li>
              <li>I am responsible for any use of any OGOW System performed under my login information.</li>
              <li>I will not leave my workstation unattended without logging out or securing my workstation or application.</li>
              <li>I will not use or obtain another person’s login information.</li>
              <li>If I believe my login information may be known by another person, I will immediately change my password and notify the OGOW Information & Privacy Department [privacy@ogowhealth.com].</li>
            </ul>

            <p><strong>Confidentiality Provisions</strong></p>
            <ul>
              <li>If I become aware of a violation of a policy referenced above or a potential or actual breach of confidentiality, I will notify my Supervisor immediately. I will also notify the OGOW Information & Privacy Department as soon as possible [privacy@ogowhealth.com].</li>
              <li>I will not disclose to another person or entity the dashboard or any other confidential information, except where it must be accessed or used, for any purpose other than is required for compliance with a Party’s authorized use of the Confidential Information.</li>
            </ul>

            <p><strong>Audit and Sanctions</strong></p>
            <ul>
              <li>I understand and acknowledge that OGOW, and/or Government Partners may conduct random audits of OGOW Systems and may audit my use of any OGOW System without notice.</li>
              <li>I acknowledge that I have read the policies referenced above and understand the consequences for a violation of those policies and/or this Agreement.</li>
            </ul>
            <Container className="border p-3">
              <Form>
                <Form.Check
                  type="checkbox"
                  id="agreementCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label={<>
                    <span >
                      I, <span style={{ color: "red" }}>{user?.data?.firstName + " " + user?.data?.lastName}</span> (email – <a href="mailto:xyz@gmail.com" style={{ color: "red", textDecoration: "none" }}>{user?.data?.email}</a>) <span style={{ color: "black" }}>understand and accept the terms of the agreement detailed above.</span>
                    </span>
                  </>

                  }
                />
                <div className="mt-2 ml-5" style={{ color: "black" }}>{formattedDate}</div>
              </Form>
            </Container>
          </div>
          {/* {data && data?.data?.agreement &&
            <>
              <div dangerouslySetInnerHTML={{ __html: data?.data?.agreement }} style={{ maxHeight: "500px", overflowY: "scroll" }} />
              <Container className="border p-3">
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="agreementCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    label={<>
                      <span >
                        I, <span style={{ color: "red" }}>AAA BBB</span> (email – <a href="mailto:xyz@gmail.com" style={{ color: "red", textDecoration: "none" }}>xyz@gmail.com</a>) <span style={{ color: "black" }}>understand and accept the terms of the agreement detailed above.</span>
                      </span>
                    </>

                    }
                  />
                  <div className="mt-3" style={{ color: "black" }}>Date – yyyy – mm – dd</div>
                </Form>
              </Container>
            </>
          } */}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={!isChecked} onClick={handleContinue}>
            Countnue
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Switch>
        <Route path="/termsandconditions" component={TermsandCondtitions} />
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to={filteredPolicies && filteredPolicies.length > 0 && filteredPolicies?.[0]?.path} />
          // <Redirect from="/auth" to={'/'} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <BasePage />
          </Layout>
        )}
      </Switch>
    </>
  );
}

