import { request, success, failure, ADMIN_REQUEST } from "./utilities";
import {
  addEditAdminApi,
  addEditTenantApi,
  assignedClinicToAdminApi,
  getAdminClinicListApi,
  getAllAdminListApi,
  getAllTenantListApi,
  getSingleAdminDetailsApi,
  setAdminActiveApi,
} from "../api";
import { toast } from "react-toastify";


export function getAllAdminList(filters) {
  return (dispatch) => {
    dispatch(request(ADMIN_REQUEST.GET_ADMIN_LIST_REQUEST));
    getAllAdminListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(ADMIN_REQUEST.GET_ADMIN_LIST_SUCCESS, response.data.data, {
              count: response.data.totalRecords,
            })
          );
        } else {
          dispatch(
            failure(ADMIN_REQUEST.GET_ADMIN_LIST_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(ADMIN_REQUEST.GET_ADMIN_LIST_FAILURE, error.message));
      }
    );
  };
}
export function getAllTenantList(filters) {
  return (dispatch) => {
    dispatch(request(ADMIN_REQUEST.GET_ADMIN_LIST_REQUEST));
    getAllTenantListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(ADMIN_REQUEST.GET_ADMIN_LIST_SUCCESS, response.data.data, {
              count: response.data.totalRecords,
            })
          );
        } else {
          dispatch(
            failure(ADMIN_REQUEST.GET_ADMIN_LIST_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(ADMIN_REQUEST.GET_ADMIN_LIST_FAILURE, error.message));
      }
    );
  };
}

export function getSingleAdminDetails(clinicId) {
  return (dispatch) => {
    dispatch(request(ADMIN_REQUEST.GET_SINGLE_ADMIN_DETAILS_REQUEST));
    getSingleAdminDetailsApi(clinicId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ADMIN_REQUEST.GET_SINGLE_ADMIN_DETAILS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              ADMIN_REQUEST.GET_SINGLE_ADMIN_DETAILS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ADMIN_REQUEST.GET_SINGLE_ADMIN_DETAILS_FAILURE, error.message)
        );
      }
    );
  };
}

export function setAdminActive(rec) {
  return (dispatch) => {
    dispatch(request(ADMIN_REQUEST.SET_ADMIN_ACTIVE_REQUEST));
    setAdminActiveApi(rec).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(success(ADMIN_REQUEST.SET_ADMIN_ACTIVE_SUCCESS, rec));
        } else {
          dispatch(
            failure(
              ADMIN_REQUEST.SET_ADMIN_ACTIVE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ADMIN_REQUEST.SET_ADMIN_ACTIVE_FAILURE, error.message)
        );
      }
    );
  };
}

export function addEditAdmin(rec, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(ADMIN_REQUEST.ADD_EDIT_ADMIN_REQUEST));
    addEditAdminApi(rec)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(success(ADMIN_REQUEST.ADD_EDIT_ADMIN_SUCCESS, rec));
          } else {
            onFailure = true;

            dispatch(
              failure(
                ADMIN_REQUEST.ADD_EDIT_ADMIN_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(ADMIN_REQUEST.ADD_EDIT_ADMIN_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function addEditTenant(rec, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;
    dispatch(request(ADMIN_REQUEST.ADD_EDIT_ADMIN_REQUEST));
    addEditTenantApi(rec)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;
            dispatch(success(ADMIN_REQUEST.ADD_EDIT_ADMIN_SUCCESS, rec));
            if (onSuccessCallback) {
              onSuccessCallback()
            }
            toast.success(response.data.message, {
              autoClose: 3000,
            });
          } else {
            toast.error(response.data.message, {
              autoClose: 3000,
            });
            onFailure = true;

            dispatch(
              failure(
                ADMIN_REQUEST.ADD_EDIT_ADMIN_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(ADMIN_REQUEST.ADD_EDIT_ADMIN_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function getAdminClinicList(adminId) {
  return (dispatch) => {
    dispatch(request(ADMIN_REQUEST.GET_ADMIN_CLINIC_LIST_REQUEST));
    getAdminClinicListApi(adminId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ADMIN_REQUEST.GET_ADMIN_CLINIC_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              ADMIN_REQUEST.GET_ADMIN_CLINIC_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ADMIN_REQUEST.GET_ADMIN_CLINIC_LIST_FAILURE, error.message)
        );
      }
    );
  };
}

export function assignedClinicToAdmin(data, onSuccessFunc) {
  return (dispatch) => {
    dispatch(request(ADMIN_REQUEST.ASSIGN_ADMIN_CLINIC_REQUEST));
    assignedClinicToAdminApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ADMIN_REQUEST.ASSIGN_ADMIN_CLINIC_SUCCESS,
              response.data.data
            )
          );
          if (onSuccessFunc) {
            onSuccessFunc(response.data.message);
          }
        } else {
          dispatch(
            failure(
              ADMIN_REQUEST.ASSIGN_ADMIN_CLINIC_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ADMIN_REQUEST.ASSIGN_ADMIN_CLINIC_FAILURE, error.message)
        );
      }
    );
  };
}
