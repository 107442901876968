import React, { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import {
  // FormattedMessage,
  injectIntl,
} from "react-intl";
import * as auth from "../_redux/authRedux";
import { changePassword } from "../_redux/authCrud";
import queryString from "query-string";
import { removeFalsyElement } from "../../../constant";

function ResetPassword(props) {
  useEffect(() => {
    document.title = "Reset Password | OGOW Health";
  }, []);

  const pageParams = queryString.parse(window.location.search);
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = {
    email: "",
    phone: "",
    password: "",
    changepassword: "",
    otpCode: "",
    userName: pageParams?.userName
    // ...pageParams,
  };

  const RegistrationSchema = Yup.object().shape(
    {
      // email: Yup.string()
      //   .email("Wrong email format")
      //   .when("phone", {
      //     is: (value) => !value,
      //     then: Yup.string().required(
      //       intl.formatMessage({
      //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
      //       })
      //     ),
      //   }),
      // phone: Yup.string().when("email", {
      //   is: (value) => !value,
      //   then: Yup.string().required(
      //     intl.formatMessage({
      //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
      //     })
      //   ),
      // }),
      password: Yup.string()
        .min(6, "Minimum 6 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        ),
      changepassword: Yup.string()
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        )
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password and Confirm Password didn't match"
          ),
        }),
      otpCode: Yup.string()
        .matches(/^\d+$/, "OTP can be digit only")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        ),
    },
    ["otpCode", "phone", "password"]
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      const dataObj = removeFalsyElement({
        // email: values.email,
        phone: values.phone,
        newPassword: values.password,
        confirmPassword: values.changepassword,
        confirmPassword: values.changepassword,
        userName: pageParams?.userName,
        otp: values.otpCode,
      });
      changePassword(dataObj)
        .then(async (data) => {
          let response = data.data;

          if (!response.success) {
            setSubmitting(false);
            setStatus(response.message);
            disableLoading();
            return;
          }

          disableLoading();
          setSubmitting(false);
          setIsSuccess(response.message);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus("Network Error");
          disableLoading();
        });
    },
  });

  return (
    <Fragment>
      {!Object.entries(pageParams).length && (
        <Redirect to="/auth/forgot-password" />
      )}

      <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password</h3>
          <p className="text-muted font-weight-bold">
            Enter your new passowrd and OTP to change passowrd
          </p>
        </div>

        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* end: Alert */}

          {/* begin: Email */}
          {/* {pageParams.email && (
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                disabled
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
          )} */}
          {/* end: Email */}

          {/* begin: Phone */}
          {pageParams.phone && (
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Phone"
                type="phone"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "phone"
                )}`}
                name="phone"
                disabled
                {...formik.getFieldProps("phone")}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.phone}</div>
                </div>
              ) : null}
            </div>
          )}
          {/* end: Phone */}

          {/* begin: Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              autocomplete="off"

              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/* end: Password */}

          {/* begin: Confirm Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Confirm Password"
              type="password"
              autocomplete="off"

              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "changepassword"
              )}`}
              name="changepassword"
              {...formik.getFieldProps("changepassword")}
            />
            {formik.touched.changepassword && formik.errors.changepassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.changepassword}
                </div>
              </div>
            ) : null}
          </div>
          {/* end: Confirm Password */}

          {/* begin: OTP */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="OTP Code"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "otpCode"
              )}`}
              name="otpCode"
              {...formik.getFieldProps("otpCode")}
            />
            {formik.touched.otpCode && formik.errors.otpCode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.otpCode}</div>
              </div>
            ) : null}
          </div>
          {/* end: OTP */}

          {formik.status && (
            <div className="d-flex justify-content-end mb-5">
              <Link to="/auth/forgot-password">Resend OTP</Link>
            </div>
          )}

          {/* begin: Alert */}
          {isSuccess && (
            <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
              <div className="alert-text font-weight-bold">{isSuccess}</div>
              <div className="alert-text font-weight-bold">
                <Link to="/auth">Login Now</Link>
              </div>
            </div>
          )}
          {/* end: Alert */}

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              type="submit"
              // disabled={formik.isSubmitting}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <span>Submit</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>

            <Link to="/auth/forgot-password">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
