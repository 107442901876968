import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get General Dashboard Stats Data
 *
 * @param {Object} filterData
 * @return {*} Promise
 */
export function getStatsApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getStats}`, {
    params: filterData,
  });
}
export function getGenericDashboardStatsApi(filterData, urlState) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getGenericDashboardStats}/${urlState}`, {
    params: filterData,
  });
}

export function getExportGenericDashboardStatsApi(filterData, urlState) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getGenericDashboardStats}/${urlState}`, {
    params: filterData,
    responseType: "blob",
  });
}
export function getExportClinicApi(filterData, urlState) {
  return axios.get(`${API_PATH.CLINICS.exportClinic}/${urlState}`, {
    params: filterData,
    responseType: "blob",
  });
}

/**
 * Get History Graph Data Api
 *
 * @param {Object} filterData
 * @return {*}
 */
export function getHistoryGraphApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getHistoryChart}`, {
    params: filterData,
  });
}

/**
 * Get General Dashboard Case Table Data
 *
 * @param {Object} filterData
 * @return {*}
 */
export function getCaseProfileTableApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getCaseProfiles}`, {
    params: filterData,
  });
}
export function getBatchListApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getBatchList}`, {
    params: filterData,
  });
}
export function getAllBatchListApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.GetAllBatchStockList}`, {
    params: filterData,
  });
}
export function getTargetListApi(filterData) {

  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getTargetList}`, {
    params: filterData,
  });
}
export function addBatchApi(filterData) {
  return axios.post(`${API_PATH.GENERAL_DASHBOARD.addBatch}`, filterData);
}

export function addBulkBatchApi(file) {
  return axios.post(API_PATH.GENERAL_DASHBOARD.addBatchBulkUpload, file
  );
}

export function addTargetApi(filterData) {
  return axios.post(`${API_PATH.GENERAL_DASHBOARD.addTarget}`, filterData);
}

export function updateBatchApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.updateBatch}`, {
    params: filterData,
  });
}
export function deleteBatchApi(filterData) {
  return axios.post(`${API_PATH.GENERAL_DASHBOARD.deleteBatch}`, filterData);
}
export function deleteTargetApi(filterData) {
  return axios.post(`${API_PATH.GENERAL_DASHBOARD.deleteTarget}`, filterData);
}

/**
 * Get All Records Count for Dashboard
 *
 * @param {Object} filterData
 * @return {*} Promise
 */
export function getAllCountApi() {
  return axios.get(API_PATH.GENERAL_DASHBOARD.getAllCount);
}

/**
 * Get Sms Nudges Table List Data
 *
 * @param {*} filterData
 * @return {*}
 */
export function getSmsNudgesListApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getSmsNudges}`, {
    params: filterData,
  });
}
export function getIVRListingApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getIVRListing}`, {
    params: filterData,
  });
}

/**
 * Get Notify Visitors Table List Data
 *
 * @param {*} filterData
 * @return {*}
 */
export function getNotifyVisitorsListApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getNotifyVisitors}`, {
    params: filterData,
  });
}

/**
 * Api for Export Excel for History Graph
 *
 * @param {*} filterData
 * @return {*}
 */
export function exportHistoryGraphDataApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getHistoryExcel}`, {
    params: filterData,
    responseType: "blob",
  });
}

/**
 * Api for Export Excel for Notify Users
 *
 * @param {*} filterData
 * @return {*}
 */
export function exportNotifyUserDataApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getNotifyUserExcel}`, {
    params: filterData,
    responseType: "blob",
  });
}

/**
 * Api for Export Excel for IVRs
 *
 * @param {*} filterData
 * @return {*}
 */
export function exportIVRDataApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getIVRExcel}`, {
    params: filterData,
    responseType: "blob",
  });
}
/**
 * Send manual message for not visited patient
 *
 * @param {Object} filterData
 * @return {*} Promise
 */
export function sendNotifiedUserManualMessageApi(data) {
  return axios.post(API_PATH.GENERAL_DASHBOARD.sendManualMessage, data);
}
export function getGlobalPlusDashboardApi(baseUrl, filterData, urlState) {
  const { treatmentType, ...restFilters } = filterData
  return axios.get(`${baseUrl}/${urlState}`, {
    params: urlState === "TreatmentOfUncomplicatedSAM" || urlState === "TreatmentOfComplicatedSAM" ? restFilters : filterData,
  });
}
export function getGenericChildDetailsApi(baseUrl, filterData, urlState) {
  return axios.get(`${baseUrl}/${urlState}`, {
    params: filterData,
  });
}
export function getNutritionSummaryApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getNutritionSummary}`, {
    params: filterData,
  });
}