import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';

const FilterContext = createContext(undefined);

export function FilterProvider({ children }) {
    const [statsFilters, setStatsFilters] = useState({
        tableState: {
            PageSize: 10,
            PageNumber: 1, // You can modify this based on locReducer if needed
        },
        filters: {
            // StockType: null,
            ChartType: '2',
            StartDate: moment().startOf('month').format('YYYY-MM-DD'),
            EndDate: moment().endOf('month').format('YYYY-MM-DD'),
            AgeLimit: 0,
            ComparisonType: '',
            AgeLimitStart: '',
            AgeLimitEnd: '',
            DurationType: 2,
            treatmentType: null,
            graphYear: new Date().getFullYear(),
            ClinicIds: null,
            isBatchFilter: false,
            activeTab: "globalDashboard",
            innerTab: "Overview",
        },
        values: {
            Month: {
                value: new Date().getMonth() + 1, // Get current month (1-12)
                label: moment().format('MMMM'),
            },
            Year: {
                value: new Date().getFullYear(),
                label: new Date().getFullYear().toString(),
            },
            AgeLimit: { value: '', label: 'All Ages' },
        },
    });

    return (
        <FilterContext.Provider value={{ statsFilters, setStatsFilters }}>
            {children}
        </FilterContext.Provider>
    );
}

export function useFilters() {
    const context = useContext(FilterContext);
    if (context === undefined) {
        throw new Error('useFilters must be used within a FilterProvider');
    }
    return context;
}