import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Admin List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getAllLogginActivityApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityList, {
        params: filters,
    });
}
export function loginAccountWith2FaApi(req) {
    return axios.post(API_PATH.AUTH_API.LOGIN_WITH_2FA, req
    );
}
// export function getLoggingActivityAnalyticsApi(filters) {

//     return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityAnalytics, {
//         params: filters,
//     });
// }
export function getLoggingActivityAnalyticsApi(filters, cancelToken) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityAnalytics, {
      params: filters,
      cancelToken: cancelToken,
    });
  }
  
  // Usage example:
  getLoggingActivityAnalyticsApi({ param1: "value1" })
    .then((data) => {
      console.log("Analytics data:", data);
    });
  
  setTimeout(() => {
    getLoggingActivityAnalyticsApi({ param2: "value2" }); // Cancels the first call
  }, 1000);
export function getAllTallyValidationApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getAllTallyValidation, {
        params: filters,
    });
}
export function getLoggingActivityExportApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityExport, {
        params: filters,
        responseType: "blob",
    });
}
export function getLoggingActivityChartApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityChart, {
        params: filters,
    });
}