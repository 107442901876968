/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import Dropdown from "react-bootstrap/Dropdown";
import queryString from "query-string";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { FaCogs } from "react-icons/fa";
import { BG1, Profile } from "../../../../assets";
import { Button, Modal } from "react-bootstrap";
import { changePassword, logout } from "../../../../../app/modules/Auth/_redux/authCrud";
import { Link } from "react-router-dom";
import { IoCloseCircle } from "react-icons/io5";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { removeFalsyElement } from "../../../../../app/constant";

export function UserProfileDropdown(props) {
  const [isOpen, setisOpen] = useState(false)
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const pageParams = queryString.parse(window.location.search);
  const onClose = () => {
    setisOpen(false)
  }
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  let history = useHistory();
  const Logout = () => {

    logout()
      .then((resp) => resp.data)
      .then((data) => { })
      .catch((response) => { })
      .finally(() => {
        history.push("/logout");
        window.localStorage.removeItem("tokenExpiry");
        window.localStorage.removeItem("user_refresh_token");
      });
  };



  const initialValues = {
    email: "",
    phone: "",
    password: "",
    changepassword: "",
    otpCode: "",
    // ...pageParams,
  };

  const RegistrationSchema = Yup.object().shape(
    {
      email: Yup.string()
        .email("Invalid email format")
        .when("phone", {
          is: (value) => !value,
          then: Yup.string().required("Email is a required field if phone is not provided"),
        }),
      phone: Yup.string().when("email", {
        is: (value) => !value,
        then: Yup.string().required("Phone is a required field if email is not provided"),
      }),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(50, "Password cannot exceed 50 characters")
        .required("Password is a required field"),
      changepassword: Yup.string()
        .required("Confirm Password is a required field")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password and Confirm Password must match"
          ),
        }),
      otpCode: Yup.string()
        .matches(/^\d+$/, "OTP must contain digits only")
        .min(3, "OTP must be at least 3 digits")
        .max(50, "OTP cannot exceed 50 digits")
        .required("OTP is a required field"),
    },
    ["email", "phone"]
  );


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();

      const dataObj = removeFalsyElement({
        email: values.email,
        phone: values.phone,
        newPassword: values.password,
        confirmPassword: values.changepassword,
        otp: values.otpCode,
      });

      changePassword(dataObj)
        .then(async (data) => {
          let response = data.data;

          if (!response.success) {
            setSubmitting(false);
            setStatus(response.message);
            disableLoading();
            return;
          }

          disableLoading();
          setSubmitting(false);
          setIsSuccess(response.message);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus("Network Error");
          disableLoading();
        });
    },
  });
  return (
    <>
      <Dropdown drop="down" alignRight>
        {/* <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi,
            </span>{" "}
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user?.data?.firstName} {user?.data?.lastName}
            </span>
            <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {user?.data?.firstName?.[0]}
              </span>
            </span>
          </div>
        </Dropdown.Toggle> */}
        <Dropdown.Toggle
          as="div"
          id="dropdown-toggle-user-profile"
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            <div
              className="symbol symbol-35"
              style={{
                width: "45px",
                height: "45px",
                marginRight: "10px",
                border: "3px solid #13B9A0",
                borderRadius: "50%",
                overflow: "hidden",
                // backgroundColor: "#13B9A0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* User Avatar or Initial */}
              {/* <span
                className="font-weight-bold text-dark"
                style={{ fontSize: "16px", }}
              >
                <span style={{ fontSize: "16px", color: "white" }}> {user?.data?.firstName?.[0] || "U"}</span>
                <span style={{ fontSize: "16px", color: "white" }}> {user?.data?.lastName?.[1] || "U"}</span>
              </span> */}
              <img src={Profile} alt="icon" />
            </div>
            <div className="d-flex flex-column text-left mr-3">
              <span
                className="font-weight-bold"
                style={{ color: "#000", fontSize: "16px" }}
              >
                {user?.data?.firstName} {user?.data?.lastName}
              </span>
              <span
                className="text-muted"
                style={{ fontSize: "14px", color: "#6c757d" }}
              >
                {user?.data?.roles?.[0]}
              </span>
            </div>
          </div>
          {/* <IoIosArrowDown /> */}
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <>
            {layoutProps.light && (
              <>
                <div className="d-flex align-items-center p-8 rounded-top">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {user?.data?.firstName} {user?.data?.lastName}
                  </div>
                  <span className="label label-light-success label-lg font-weight-bold label-inline">
                    3 messages
                  </span>
                </div>
                <div className="separator separator-solid"></div>
              </>
            )}
            {!layoutProps.light && (
              <div
                className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                style={{
                  backgroundImage: `url(${BG1})`,
                }}
              >
                <div className="symbol bg-white-o-15 mr-3">
                  <span className="symbol-label text-success font-weight-bold font-size-h4">
                    {user?.data?.firstName?.[0]}
                  </span>
                </div>
                <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                  {user?.data?.firstName} {user?.data?.lastName}
                </div>
                <span className="label label-success label-lg font-weight-bold label-inline">
                  {user?.data?.roles?.[0]}
                </span>
              </div>
            )}
          </>
          <div className="navi navi-spacer-x-0 pt-5">
            {/* <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <FaCogs />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold" onClick={() => setisOpen(true)}>Change Password</div>
                  <div className="text-muted">Change account password</div>
                </div>
              </div>
            </a> */}
            <div className="navi-separator mt-3"></div>
            <div className="navi-footer justify-content-center px-8 py-5">
              <Button
                onClick={() => Logout()}
                className="btn btn-light-primary font-weight-bold"
              >
                Sign Out
              </Button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={isOpen} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-10">
            <h3 style={{ fontSize: '20px', fontWeight: '600' }}>{"Reset Password"}</h3>
            <IoCloseCircle size={25} style={{ cursor: 'pointer' }} onClick={onClose} />
          </div>
          <div className="login-form login-signin" style={{ display: "block" }}>
            {/* <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">Reset Password</h3>
                <p className="text-muted font-weight-bold">
                  Enter your new passowrd and OTP to change passowrd
                </p>
              </div> */}

            <form
              id="kt_login_signin_form"
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              onSubmit={formik.handleSubmit}
            >
              {/* begin: Alert */}
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}
              {/* end: Alert */}

              {/* begin: Email */}

              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>

              {/* end: Email */}

              {/* begin: Phone */}

              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Phone"
                  type="phone"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "phone"
                  )}`}
                  name="phone"
                  {...formik.getFieldProps("phone")}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.phone}</div>
                  </div>
                ) : null}
              </div>

              {/* end: Phone */}

              {/* begin: Password */}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Password"
                  autocomplete="off"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.password}</div>
                  </div>
                ) : null}
              </div>
              {/* end: Password */}

              {/* begin: Confirm Password */}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Confirm Password"
                  type="password"
                  autocomplete="off"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "changepassword"
                  )}`}
                  name="changepassword"
                  {...formik.getFieldProps("changepassword")}
                />
                {formik.touched.changepassword && formik.errors.changepassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.changepassword}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* end: Confirm Password */}

              {/* begin: OTP */}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="OTP Code"
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "otpCode"
                  )}`}
                  name="otpCode"
                  {...formik.getFieldProps("otpCode")}
                />
                {formik.touched.otpCode && formik.errors.otpCode ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.otpCode}</div>
                  </div>
                ) : null}
              </div>
              {/* end: OTP */}

              {/* {formik.status && (
                  <div className="d-flex justify-content-end mb-5">
                    <Link to="/auth/forgot-password">Resend OTP</Link>
                  </div>
                )} */}

              {/* begin: Alert */}
              {isSuccess && (
                <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                  <div className="alert-text font-weight-bold">{isSuccess}</div>
                  <div className="alert-text font-weight-bold">
                    <Link to="/auth">Login Now</Link>
                  </div>
                </div>
              )}
              {/* end: Alert */}

              <div className="form-group d-flex flex-wrap  justify-content-end mb-0">
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="btn btn-primary font-weight-bold px-9 py-4 mt-3 mx-4"
                >
                  <span>Submit</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 mt-3 ml-4"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
