import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { removeFalsyElement } from "../../../constant";
import queryString from "query-string";

const initialValues = {
  // email: "",
  // phone: "",
  userName: "",

};

function ForgotPassword(props) {
  useEffect(() => {
    document.title = "Forgot Password | OGOW Health";
  }, []);
 const [loading, setLoading] = useState(false);
  const { intl } = props;
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape(
    {
      // email: Yup.string()
      //   .email("Wrong email format")
      //   .when("phone", {
      //     is: (value) => !value,
      //     then: Yup.string().required(
      //       intl.formatMessage({
      //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
      //       })
      //     ),
      //   }),
      // phone: Yup.string().when("email", {
      //   is: (value) => !value,
      //   then: Yup.string().required(
      //     intl.formatMessage({
      //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
      //     })
      //   ),
      // }),
      // email: Yup.string()
      //   .email("Wrong email format")
      //   .when("phone", {
      //     is: (phone) => !phone,
      //     then: Yup.string()
      //       .required("Either email or phone is required.")
      //       .nullable(),
      //   }),
      // phone: Yup.string().when("email", {
      //   is: (email) => !email,
      //   then: Yup.string()
      //     .required("Either email or phone is required.")
      //     .nullable(),
      // }),
      userName: Yup.string().required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    },

    ["userName"]
  );

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const finalObj = removeFalsyElement(values);
      setSubmitting(true);
      enableLoading();
      requestPassword(finalObj)
        .then((data) => {
          if (!data.data.success) {
            setIsRequested(false);
            setSubmitting(false);
            disableLoading();
            setStatus(data.data.message);
            return;
          }
          setIsRequested(true);
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus("Network Error");
        });
    },
  });

  return (
    <>
      {isRequested && (
        <Redirect
          // to="/auth/reset_password"
          to={`/auth/reset_password?${queryString.stringify(
            removeFalsyElement(formik.values)
          )}`}
        />
      )}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* <div className="form-group fv-plugins-icon-container mb-5">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                placeholder="Email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div> */}

            {/* <div className="d-flex justify-content-center my-5">OR</div> */}

            {/* <div className="form-group fv-plugins-icon-container ">
              <input
                type="phone"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "phone"
                )}`}
                placeholder="Phone"
                {...formik.getFieldProps("phone")}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.phone}</div>
                </div>
              ) : null}
            </div> */}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                name="userName"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "userName"
                )}`}
                placeholder="User Name"
                {...formik.getFieldProps("userName")}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.userName}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
