import React, { useEffect, useState } from "react";
import { Button, Spinner, Modal, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getAllClinicsList,
  getAllClinicsListForSelectAction,
  getUsersSelectListExceptPatient,
} from "../../redux/actions";
import { format } from "date-fns";

import {
  getCurrentMonthInNumber,
  getCurrentMonthName,
  getCurrentYear,
  handleFilters,
  OPTION,
} from ".";
import { ClinicsPicker } from "../pages/ClinicList/ClinicPicker";
import { GenericDatePicker } from "../pages/ClinicList/Pages/GenericDatePicker";
import moment from "moment";

export const ExportModal = ({
  title,
  show,
  handleClose,
  isLoading,
  onSubmit,
  hideAge,
  showPatientTypeFilter,
  showSelectUser,
  showAllVisited,
  showAnsweredStatus = false,
  showDateRangePicker = false
}) => {


  const [columnSize, setColumnSize] = useState(false);

  const [clinicData, setClinicData] = useState({
    clinics: null,
    label: null,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersSelectListExceptPatient());

    // eslint-disable-next-line
  }, []);
  const clinics = useSelector((state) => state.clinicsReducer);
  const genericReducer = useSelector((state) => state.genericReducer);
  const [userSelectListForLogging, setUserSelectListForLogging] = useState([]);
  const locationReducer = useSelector(
    (state) => state.locationReducer?.allLocationsList
  );
  const intialState = {
    filters: {
      StartDate: moment(start).format("YYYY-MM-DD"),
      EndDate: moment(end).format("YYYY-MM-DD"),
      Month: getCurrentMonthInNumber(),
      Year: getCurrentYear(),
      AgeLimit: "0",
      GenderId: "",
      PatientType: "",
      UserId: null,
      IsVisited: "0",
    },
    values: {
      Month: [
        {
          value: getCurrentMonthInNumber(),
          label: getCurrentMonthName(),
        },
      ],
      Year: {
        value: getCurrentYear(),
        label: getCurrentYear(),
      },
      AgeLimit: { value: "0", label: "All Ages" },
      GenderId: { value: "", label: "All Genders" },
      PatientType: { value: "", label: "All Patients" },
      UserId: null,
      IsVisited: { value: "0", label: "All" },

      CallStatus: { value: 0, label: "All" },

    },
  };
  let now = new Date();
  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate())
  );
  let end = moment(start)
    .add(1, "days")
    .subtract(1, "seconds");
  const [stateFilters, setStateFilters] = useState(intialState);
  const [filterOpt, setFilterOpt] = useState('')
  const [locState, setLocState] = useState({
    selectedState: null,
    selectedRegion: null,
    selectedDistrict: null,
    filteredDistricts: [],
    filteredRegions: [],
    values: {
      selectedState: null,
      selectedRegion: null,
      selectedDistrict: null,
    },
  });

  useEffect(() => {
    let filters = {
      State: locState ? locState?.selectedState : "",
      Region: locState ? locState?.selectedRegion : "",
      City: locState ? locState?.selectedDistrict : "",
    };
    dispatch(getAllClinicsList(filters));
  }, [dispatch, locState]);
  // useEffect(()=>{

  // },[])

  const changeState = (value) => {
    if (value !== null) {
      let data = locationReducer?.states?.find(
        (x) => x.locationName === value.value
      )?.id;
      setLocState({
        ...locState,
        selectedState: value?.value,
        filteredRegions: locationReducer?.regions?.filter(
          (s) => s.stateId === data
        ),
        values: {
          ...locState.values,
          selectedState: value,
          selectedRegion: null,
          selectedDistrict: null,
        },
      });
      setStateFilters({
        ...stateFilters,
        values: {
          ...stateFilters.values,
          ClinicId: null,
        },
      });
    }
  };
  const changeRegion = (value) => {
    if (value !== null) {
      let data = locationReducer?.regions?.find(
        (x) => x.locationName === value.value
      )?.id;
      setLocState({
        ...locState,
        selectedRegion: value?.value,
        filteredDistricts: locationReducer?.districts?.filter(
          (s) => s.regoinId === data
        ),
        values: {
          ...locState.values,
          selectedRegion: value,
          selectedDistrict: null,
        },
      });
      setStateFilters({
        ...stateFilters,
        values: {
          ...stateFilters.values,
          ClinicId: null,
        },
      });
    }
  };
  const changeDistrict = (value) => {
    if (value !== null) {
      setLocState({
        ...locState,
        selectedDistrict: value?.value,
        values: {
          ...locState.values,
          selectedDistrict: value,
        },
      });
    }
  };

  const config = {
    state: stateFilters,
    setState: setStateFilters,
  };
  useEffect(() => {
    userSelectListForLoggingActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericReducer.getUserSelectListExceptPatient]);

  function userSelectListForLoggingActivity() {
    let userSelectListExceptPatient =
      genericReducer && genericReducer.getUserSelectListExceptPatient
        ? genericReducer.getUserSelectListExceptPatient
        : [];
    let options = [];

    options = userSelectListExceptPatient.map((item, index) => {
      return {
        label: item.firstName,
        value: item.id,
      };
    });

    setUserSelectListForLogging(options);
  }
  // const changeUser = (value) => {
  //   setStateFilters({
  //     ...stateFilters,
  //     userValue: value || "",
  //     filters: {
  //       ...stateFilters.filters,
  //       userValue: value ? value.value : null,
  //     },
  //   });
  // };
  const resetForm = () => setStateFilters(intialState);

  const handleSubmit = () => {
    let filters = {
      ...stateFilters.filters,
      ClinicIds: clinicData?.clinics?.map((item) => item.value).toString(),
      Month: (stateFilters.values.Month || [])
        .map((item) => item.value)
        .toString(),
    };

    const fileName = !!clinicData.label
      ? `${clinicData.label}_${format(Date.now(), "yyyy-MM-dd")}.xlsx`
      : `All Health Facilities_${format(Date.now(), "yyyy-MM-dd")}.xlsx`;

    const onSuccessFunc = () => {
      handleClose();
      resetForm();
    };
    onSubmit(filters, fileName, onSuccessFunc);
  };

  useEffect(() => {
    if (!clinics.clinicsListSelect.length > 0) {
      dispatch(getAllClinicsListForSelectAction());
    }
    //eslint-disable-next-line
  }, []);
  const onClinicsChanged = (state, region, districts, clinics, label) => {
    setClinicData({
      state: state,
      region: region,
      district: districts,
      clinics: clinics,
      label: label,
    });
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">

          {(!showDateRangePicker) && (
            <div className="col-md-12 mb-3">
              <Select
                options={OPTION.MONTH}
                className="d-inline-block w-100"
                placeholder="Month"
                isDisabled={isLoading}
                value={stateFilters.values.Month || ""}
                name="Month"
                onChange={(value, { name }) => {
                  handleFilters(value, name, config);
                }}
                isMulti
              />
            </div>
          )}
          {(!showDateRangePicker) && (
            <div className="col-md-12 mb-3">
              <Select
                options={OPTION.YEAR}
                className="d-inline-block w-100"
                id="customSlct"
                placeholder="Year"
                isDisabled={isLoading}
                value={stateFilters.values.Year || ""}
                name="Year"
                onChange={(value, { name }) => handleFilters(value, name, config)}
              />
            </div>
          )}

          {(showDateRangePicker) && (
            <div
              className={`${columnSize === true
                ? "col-md-12 mb-3"
                : "col-md-12 mb-3"
                }`}
            >
              <GenericDatePicker
                setColumnSize={setColumnSize}
                childImmunization={stateFilters}
                setChildImmunization={setStateFilters}
                filterOpt={filterOpt}
                setFilterOpt={setFilterOpt}
              />
            </div>
          )}


          {(hideAge === undefined || hideAge === true) && (
            <div className="col-md-12 mb-3">
              <Select
                options={OPTION.AGE}
                className="d-inline-block w-100"
                placeholder="Age"
                // isDisabled={
                //   isLoading ||
                //   (stateFilters.filters.PatientType === 0 ? false : true)
                // }
                name="AgeLimit"
                value={stateFilters.values.AgeLimit || ""}
                onChange={(value, { name }) =>
                  handleFilters(value, name, config)
                }
              />
            </div>
          )}
          {(hideAge === undefined || hideAge === true) && (
            <div className="col-md-12 mb-3">
              <Select
                options={OPTION.GENDER}
                className="d-inline-block w-100"
                placeholder="Gender"
                name="GenderId"
                value={stateFilters.values.GenderId || ""}
                isDisabled={
                  isLoading ||
                  (stateFilters.filters.PatientType === 0 ? false : true)
                }
                onChange={(value, { name }) =>
                  handleFilters(value, name, config)
                }
              />
            </div>
          )}
          {showAllVisited === true && (
            <div className="col-md-12 mb-3">
              <Select
                options={[
                  { label: "All", value: 0 },
                  { label: "Visited", value: 1 },
                  { label: "Not Visited", value: 2 },
                ]}
                className="d-inline-block w-100"
                placeholder="Type"
                onChange={(value, { name }) =>
                  handleFilters(value, name, config)
                }
                name="IsVisited"
                value={stateFilters.values.IsVisited || ""}
                isDisabled={isLoading}
              />
            </div>
          )}

          {showAnsweredStatus === true && (
            <div className="col-md-12 mb-3">
              <Select
                options={[
                  { label: "All", value: 0 },
                  { label: "Answered", value: 1 },
                  { label: "Not Answered", value: 2 },
                ]}
                className="d-inline-block w-100"
                placeholder="Type"
                onChange={(value, { name }) =>
                  handleFilters(value, name, config)
                }
                name="CallStatus"
                value={stateFilters.values.CallStatus || ""}
                isDisabled={isLoading}
              />
            </div>
          )}

          {showPatientTypeFilter === true && (
            <div className="col-md-12 mb-3">
              <Select
                options={OPTION.PATIENT_TYPE || []}
                className="d-inline-block w-100"
                placeholder="Patient Type"
                onChange={(value, { name }) => {
                  handleFilters(value, name, config);
                }}
                name="PatientType"
                value={stateFilters.values.PatientType || ""}
                isLoading={clinics && clinics.getClinicListSelectLoading}
                isDisabled={isLoading}
              />
            </div>
          )}
          {showSelectUser === true && (
            <div className="col-md-12 mb-3">
              <Select
                options={userSelectListForLogging}
                className="d-inline-block w-100"
                placeholder="Select User"
                isDisabled={isLoading}
                isClearable
                value={stateFilters.values.UserId || ""}
                name="UserId"
                onChange={(value, { name }) =>
                  handleFilters(value, name, config)
                }
              />
            </div>
          )}
          <Col lg="12" className="mt-3">
            <h4 style={{ fontWeight: 700 }} className="mb-3">{"Location"}</h4>
            <ClinicsPicker
              clinicsData={clinicData}
              setClinicsData={setClinicData}
            ></ClinicsPicker>
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="button"
          disabled={
            isLoading ||
            clinicData?.clinics === null ||
            clinicData?.clinics?.length === 0 ||
            stateFilters.filters.Month === null ||
            stateFilters.filters.Month.length === 0

            //  stateFilters.filters.ClinicIds === undefined
          }
          onClick={() => handleSubmit()}
        >
          {isLoading === true && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          Export
        </Button>
        <Button variant="secondary" disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
