import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { GENERIC, LOGGING_ACTIVITY_REQUEST, failure, request } from "../../../../redux/actions/utilities";
import { useLocation } from 'react-router-dom';
import { loginAccountWith2FaAction } from "../../../../redux/actions";
import { el } from "date-fns/locale";
/*s
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    OTP: "",
    password: "",
};

function LoginWIth2Fa(props) {
    const location = useLocation();
    const data = location?.state;
    useEffect(() => {
        document.title = "Login | OGOW Health";
    }, []);
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        OTP: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };
    // eslint-disable-next-line
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };
    const onSuccess = (response) => {
        if (response?.access_token) {
            const tokenExpireTime = new Date();
            tokenExpireTime.setSeconds(
                tokenExpireTime.getSeconds() + parseInt(response?.expires_in)
            );
            window.localStorage.setItem("tokenExpiry", tokenExpireTime);
            dispatch(request(GENERIC.SET_SESSION_EXPIRE_STATE, false));
            disableLoading()
            props.login(response?.access_token);
        }
        else {
            disableLoading()
        }

    }
    let dispatch = useDispatch();
    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading()
            let req = {
                otp: values.OTP,
                userName: data?.userName,
                refreshToken: data?.data?.refresh_token
            }
            dispatch(loginAccountWith2FaAction(req, onSuccess))
        },
    });
    const [showMessage, setShowMessage] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);
    let twoFactorReducer = useSelector((state) => state?.loggingActivityReducer)
    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter OTP
                </p>

            </div>
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                <div className="form-group fv-plugins-icon-container">
                    {showMessage && (
                        <div className="mb-10 " style={{ background: "#c4c8ff", padding: "15px 20px", borderRadius: "6px", color: "#13B9A0", fontWeight: "600" }}>
                            <div className="alert-text ">{"An OTP has been sent to your phone number"}</div>
                        </div>
                    )}
                    {twoFactorReducer?.loginWith2FAFailure &&
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{twoFactorReducer?.loginWith2FAError || "Invalid OTP"}</div>
                        </div>
                    }
                    <input
                        placeholder="Enter OTP"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="OTP"
                        {...formik.getFieldProps("OTP")}
                    />
                    {formik.touched.OTP && formik.errors.OTP ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.OTP}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Confirm OTP</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(LoginWIth2Fa));
