/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import "./custom.css";
import "./NutritionAssessmentTable.css";
import "./DashboardTabs.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";
import { FilterProvider } from "./app/constant/GenericFilterDetails";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <StrictMode>
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <FilterProvider>
              <App store={store} persistor={persistor} basename={PUBLIC_URL} />
            </FilterProvider>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </StrictMode>,
  document.getElementById("root")
);
