import {
  request,
  success,
  failure,
  LOGGING_ACTIVITY_REQUEST,
} from "./utilities";
import axios from "axios";
import {
  getAllLogginActivityApi,
  getAllTallyValidationApi,
  getLoggingActivityAnalyticsApi,
  getLoggingActivityChartApi,
  getLoggingActivityExportApi,
  loginAccountWith2FaApi,
} from "../api/loggingActivityApi";
import { downloadBase64File } from "../../app/constant";
let cancelTokenSource;
export function loginAccountWith2FaAction(data, onSuccess) {

  return (dispatch) => {
    dispatch(
      request(LOGGING_ACTIVITY_REQUEST.LOGIN_WITH_2FA_REQUEST)
    );
    loginAccountWith2FaApi(data).then(
      (response) => {

        if (response.data.access_token) {
          dispatch(
            success(
              LOGGING_ACTIVITY_REQUEST.LOGIN_WITH_2FA_SUCCESS,
              response.data.data,
              {
                count: response.data.totalRecords,
              }
            )
          );
          if (onSuccess) {
            onSuccess(response.data)
          }
        } else {
          dispatch(
            failure(
              LOGGING_ACTIVITY_REQUEST.LOGIN_WITH_2FA_FAILURE,
              response.data.message
            )
          );
          if (onSuccess) {
            onSuccess(response.data)
          }
        }
      },
      (error) => {
        dispatch(
          failure(
            LOGGING_ACTIVITY_REQUEST.LOGIN_WITH_2FA_FAILURE,
            error.message
          )
        );
        if (onSuccess) {
          onSuccess(error)
        }
      }
    );
  };
}
export function getAllLoggingActivityAction(data) {
  return (dispatch) => {
    dispatch(
      request(LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_LIST_REQUEST)
    );
    getAllLogginActivityApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_LIST_SUCCESS,
              response.data.data,
              {
                count: response.data.totalRecords,
              }
            )
          );
        } else {
          dispatch(
            failure(
              LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_LIST_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
// export function getLoggingActivityAanalyticsAction(data) {
//   return (dispatch) => {
//     dispatch(
//       request(LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_REQUEST)
//     );
//     getLoggingActivityAnalyticsApi(data).then(
//       (response) => {
//         if (response.data.success === true) {
//           dispatch(
//             success(
//               LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_SUCCESS,
//               response.data.data,
//               {
//                 count: response.data.totalRecords,
//               }
//             )
//           );
//         } else {
//           dispatch(
//             failure(
//               LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_FAILURE,
//               response.data.message
//             )
//           );
//         }
//       },
//       (error) => {
//         dispatch(
//           failure(
//             LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_FAILURE,
//             error.message
//           )
//         );
//       }
//     );
//   };
// }
export function getLoggingActivityAanalyticsAction(data) {
  return (dispatch) => {
    dispatch(
      request(LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_REQUEST)
    );

    // Cancel the previous request if there's one
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Previous request canceled.");
    }

    // Create a new cancel token
    cancelTokenSource = axios.CancelToken.source();
    getLoggingActivityAnalyticsApi(data, cancelTokenSource.token)
      .then(
        (response) => {
          if (response.data.success === true) {
            dispatch(
              success(
                LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_SUCCESS,
                response.data.data,
                {
                  count: response.data.totalRecords,
                }
              )
            );
          } else {
            dispatch(
              failure(
                LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            dispatch(
              failure(
                LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_FAILURE,
                error.message
              )
            );
          }
        }
      );
  };
}
export function getAllTallyValidationAction(data) {
  return (dispatch) => {
    dispatch(
      request(LOGGING_ACTIVITY_REQUEST.GET_ALL_TALLY_VALIDATION_REQUEST)
    );
    getAllTallyValidationApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              LOGGING_ACTIVITY_REQUEST.GET_ALL_TALLY_VALIDATION_SUCCESS,
              response.data.data,
              {
                count: response.data.totalRecords,
              }
            )
          );
        } else {
          dispatch(
            failure(
              LOGGING_ACTIVITY_REQUEST.GET_ALL_TALLY_VALIDATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            LOGGING_ACTIVITY_REQUEST.GET_ALL_TALLY_VALIDATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getLoggingActivityChartAction(data) {
  return (dispatch) => {
    dispatch(
      request(
        LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_CHART_REQUEST
      )
    );
    getLoggingActivityChartApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_CHART_SUCCESS,
              response.data.data,
              {
                count: response.data.totalRecords,
              }
            )
          );
        } else {
          dispatch(
            failure(
              LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_CHART_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_CHART_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function exportAnalyticsData(filterData, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;
    dispatch(
      request(
        LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_REQUEST
      )
    );
    getLoggingActivityExportApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;
            dispatch(
              success(
                LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName + ".xlsx");
          }
        },
        (error) => {
          onFailure = true;
          dispatch(
            failure(
              LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
